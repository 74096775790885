/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
/* Global styles */
/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('https://cdn.wallpapersafari.com/13/90/yc8Sh5.jpg'); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
}




.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form styles */
form {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

input[type="text"] {
  width: 300px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}


.weather-data {
  margin-top: 20px;
}

.card {
  padding: 20px;
  border: 1px solid black;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}
@keyframes blink {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0.5;
  }
}
.blinking-icon {
  animation: blink 2s infinite; 
}


.weather-form {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4); 
   padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 50px;
}

.card {
  display: flex;
}

.weather-data {
  margin-right: 20px;
}
